<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="field-wrapper">
    <mat-form-field appearance="fill" class="field-search">
      <mat-label>Numer Stronga lub transliteracja</mat-label>
      <input matInput formControlName="search" placeholder="Wpisz numer lub słowo">

      @if (form.get('search').hasError('min')) {
        <mat-error>
          Poprawny zakres numerów to 1 - 5624.
        </mat-error>
      }

      @if (form.get('search').hasError('max')) {
        <mat-error>
          Poprawny zakres numerów to 1 - 5624.
        </mat-error>
      }

      @if (form.get('search').hasError('invalidPattern')) {
        <mat-error>
          Wpisz numer lub słowo.
        </mat-error>
      }

      <app-icon-button label="Szukaj w konkordancji" icon="search" type="submit" class="no-border small icon-large"
      ></app-icon-button>
    </mat-form-field>
  </div>

  <div class="field-wrapper">
    <mat-form-field appearance="fill">
      <mat-label>Numery Stronga</mat-label>
      <mat-select formControlName="numbers" (selectionChange)="numbersChange($event)">
        @for (value of strongNumbers; track value) {
          <mat-option [value]="value">{{ value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
