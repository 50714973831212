import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { fromEvent, Observable, of } from 'rxjs';

import { ActiveRouteService } from '../../../services/active-route.service';
import { BibliaInfoBook } from '../../../services/biblia-info/biblia-info-book';
import { BreakpointsService } from '../../../services/breakpoints.service';
import { ConcordanceComponent } from './concordance/concordance.component';
import { ConcordanceDialogComponent } from './concordance/dialog/concordance-dialog.component';
import { BibleQueryResult } from '../bible/bible-search-query/bible-search-query-dialog/bible-search-query-dialog.component';
import { BibleSearchQueryComponent } from '../bible/bible-search-query/bible-search-query.component';
import { BookChapterUbg, ChapterUbg, Strong, StrongBasic } from '../../../services/strong/strong.model';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { MediaDirectivesModule } from '../../../directives/media/media-directives.module';
import { SelectNumberDialogComponent } from './select-number-dialog/select-number-dialog.component';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';
import { SimpleHeaderComponent } from '../../../components/simple-header/simple-header.component';
import { SnackbarService } from '../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../components/snackbar/snackbar-type.enum';
import { StrongActionsComponent } from './filter/actions/strong-actions.component';
import { StrongFilterComponent } from './filter/strong-filter.component';
import { StrongForm } from './strong.model';
import { StrongNumberPipe, StrongRootPipe, StrongTransliterationPipe } from './strong.pipe';
import { StrongScrollService } from './strong-scroll.service';
import { StrongService } from '../../../services/strong/strong.service';
import { StrongStateService } from '../../../services/strong/strong-state.service';
import { SubComponent } from '../../../components/utils/sub/sub.component';
import { VerseNumberComponent } from './verse-number/verse-number.component';

@Component({
  selector: 'app-strong',
  templateUrl: './strong.component.html',
  styleUrls: ['./strong.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BibleSearchQueryComponent,
    CommonModule,
    ConcordanceComponent,
    LoaderComponent,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    MediaDirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    SharedPipesModule,
    SimpleHeaderComponent,
    StrongFilterComponent,
    StrongNumberPipe,
    StrongRootPipe,
    StrongTransliterationPipe,
    StrongActionsComponent,
    VerseNumberComponent,
  ],
  providers: [
    StrongScrollService,
  ],
})
export class StrongComponent extends SubComponent implements OnInit, AfterViewInit {
  @HostBinding('class.full-screen') protected fullScreen = false;
  @HostBinding('class.compact-mode') protected compactMode = false;
  @HostBinding('class.show-greek') protected showGreek = false;
  @HostBinding('class.show-strong') protected showStrong = false;
  @HostBinding('class.show-transliteration') protected showTransliteration = false;

  @ViewChild(StrongFilterComponent, { static: false }) strongFilter: StrongFilterComponent;

  protected breakpointsService = inject(BreakpointsService);
  private readonly activeRouteService = inject(ActiveRouteService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly dialog = inject(MatDialog);
  private readonly elementRef = inject(ElementRef<HTMLDivElement>);
  private readonly fb = inject(FormBuilder);
  private readonly snackbarService = inject(SnackbarService);
  private readonly stateService = inject(StrongStateService);
  private readonly strongScrollService = inject(StrongScrollService);
  private readonly strongService = inject(StrongService);
  private readonly viewportScroller = inject(ViewportScroller);

  protected readonly form: FormGroup<StrongForm> = this.fb.group({
    book: this.fb.control(BibliaInfoBook.PIERWSZY_LIST_DO_KORYNTIAN),
    chapter: this.fb.control(1),
    verse: this.fb.control(1),
  });

  protected numbersUnique: number[];

  protected numbersMap = new Map<number, StrongBasic>();

  protected isLoading: boolean;
  protected isDialogLoading: boolean;

  protected book: ChapterUbg;

  protected relatedIndex: number;
  protected relatedVerse: number;
  protected loadingVerseIndex: number;

  protected actionsAbsolutePositionInPx: number;
  protected actionsAbsolute = false;

  ngOnInit(): void {
    this.activeRouteService.changeIndexes(1, null);
    this.initDataFromStorage();
  }

  ngAfterViewInit(): void {
    this.observeScrollPosition();
  }

  private updateAbsolutePositionForActions(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement.firstElementChild.firstElementChild.querySelector('.text-container');
      const rect = element.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      this.actionsAbsolutePositionInPx = rect.bottom - bodyRect.bottom + 64;
    })
  }

  private observeScrollPosition(): void {
    const resizeObservable$ = fromEvent(window, 'scroll');
    this.subscription.add(resizeObservable$
      .pipe(
        filter(() => this.breakpointsService.isDesktop && window.scrollY > 0),
      ).subscribe( () => {
        const prev = this.actionsAbsolute;
        this.updateActionsPosition();
        if (prev !== this.actionsAbsolute) {
          this.cdr.markForCheck();
        }
      })
    );
  }

  private updateActionsPosition(): void {
    this.actionsAbsolute = window.scrollY > this.actionsAbsolutePositionInPx;
  }

  protected getNumber(number: number | number[], chapter: number): void {
    if (this.isDialogLoading || !number) {
      return;
    }
    this.loadingVerseIndex = chapter;

    if (Array.isArray(number)) {
      this.openSelectNumberDialog(number);
    } else {
      this.isDialogLoading = true;
      this.fetchNumber(number);
    }
  }

  private initDataFromStorage(): void {
    this.compactMode = this.stateService.compactMode;
    this.fullScreen = this.stateService.fullScreen;
    this.showGreek = this.stateService.showGreek;
    this.showStrong = this.stateService.showStrong;
    this.showTransliteration = this.stateService.showTransliteration;
  }

  private fetchNumber(number): void {
    this.strongService.getNumber(number).pipe(
      catchError((errorRes: HttpErrorResponse) => {
        this.snackbarService.open(errorRes.error.message, SnackBarType.ERROR, 2000);
        return of(null);
      })
    ).subscribe(strong => {
      this.openConcordanceDialog(strong);
    });
  }

  protected formChange(anchorVerse?: string): void {
    this.fetchStrongData(anchorVerse);
  }

  protected mouseenter(i: number, verse: number): void {
    this.relatedIndex = i;
    this.relatedVerse = verse;
  }

  protected mouseleave(): void {
    this.relatedIndex = null;
    this.relatedVerse = null;
  }

  protected scrollTo(anchor: string): void {
    this.viewportScroller.scrollToAnchor(anchor);
  }

  protected verseChange(verse: number): void {
    this.strongScrollService.scrollToAnchor(verse);
  }

  protected bibleSearchQuery(result: BibleQueryResult): void {
    this.form.controls.book.setValue(result.book);
    this.form.controls.chapter.setValue(result.chapter);
    this.strongFilter.updateQueryParams();
    this.formChange();
  }

  private openConcordanceDialog(strong: Strong): void {
    if (!strong) {
      this.isDialogLoading = false;
      this.cdr.markForCheck();
      return;
    }
    this.dialog.open<ConcordanceDialogComponent, Strong>(ConcordanceDialogComponent, {
      data: strong, panelClass: ['reading', 'full', 'concordance']
    }).afterOpened().subscribe(() => {
      this.isDialogLoading = false;
      this.cdr.markForCheck();
    });
  }

  private fetchStrongData(anchorVerse?: string): void {
    this.isLoading = true;
    this.getBookUbgObservable().pipe(
      switchMap(() => this.strongService.getNumbersMap(this.numbersUnique))
    ).subscribe(map => {
      this.numbersMap = map;
      this.isLoading = false;
      this.updateAbsolutePositionForActions();
      this.cdr.markForCheck();

      if (Number.isInteger(+anchorVerse)) {
        setTimeout(() => this.scrollTo(anchorVerse), this.breakpointsService.isPortraitLandscape ? 600 : 100);
      }
    });
  }

  private getBookUbgObservable(): Observable<BookChapterUbg> {
    return this.strongService.getUbgChapter(
      this.form.get('book').value,
      this.form.get('chapter').value
    ).pipe(tap(bookChapter => {
      this.book = bookChapter.book[0];
      this.completeRelatedWords();
      this.filterUniqueNumbers(this.book);
    }));
  }

  private completeRelatedWords(): void {
    this.book.text.forEach(verse => {
      verse.text.forEach((word, i) => {
        if (word.with) {
          verse.text[i + word.with].with = -word.with;
          verse.text[i + word.with].n = word.n;
          verse.text[i + word.with].tr = word.tr;
          verse.text[i + word.with].na = word.na;
        }
      })
    })
  }

  private filterUniqueNumbers(book: ChapterUbg): void {
    this.numbersUnique = [...new Set(book.text
      .map(chapter => chapter.text
        .map(verse => verse.n || verse.tr || verse.na))
      .flat(2)
    )].filter(number => number > 0);
  }

  private openSelectNumberDialog(numbers: number[]): void {
    this.dialog.open<SelectNumberDialogComponent, number[]>(SelectNumberDialogComponent, { data: numbers }).afterClosed().subscribe(number => {
      if (number) {
        this.isDialogLoading = true;
        this.fetchNumber(number);
      }
    });
  }
}
