import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';

import { FormBuilder, FormControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';

@Component({
  selector: 'app-concordance-filter',
  templateUrl: './concordance-filter.component.html',
  styleUrls: ['./concordance-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconButtonComponent,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class ConcordanceFilterComponent {
  @Output() private readonly search$ = new EventEmitter<string>();
  @Output() private readonly filterNumbers$ = new EventEmitter<string>();

  private fb = inject(FormBuilder);

  protected readonly strongNumbers = [
    '1 - 500',
    '501 - 1000',
    '1001 - 1500',
    '1501 - 2000',
    '2001 - 2500',
    '2501 - 3000',
    '3001 - 3500',
    '3501 - 4000',
    '4001 - 4500',
    '4501 - 5000',
    '5001 - 5624',
  ];

  readonly form = this.fb.group({
    search: this.fb.control('', [
      Validators.min(1),
      Validators.max(5624),
      this.numberOrWord(),
    ]),
    numbers: this.fb.control('1 - 500'),
  });

  protected submit(): void {
    this.form.updateValueAndValidity();

    if (this.form.valid && this.form.get('search').value) {
      this.search$.emit(this.form.get('search').value)
    }
  }

  private numberOrWord(): ValidatorFn {
    return (form: FormControl) => (!Number.isInteger(+form.value) && /\d/.test(form.value)) ? { invalidPattern: { valid: true } } : null;
  }

  protected numbersChange(change: MatSelectChange): void {
    this.filterNumbers$.emit(change.value);
  }
}
