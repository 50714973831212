<article class="page-wrapper">
  <section class="section-container">
    <app-simple-header title="Konkordancja wyrazów greckich Stronga" icon="numbers"></app-simple-header>

    <app-concordance-filter
      (search$)="renderStrongWord($event)"
      (filterNumbers$)="filterNumbers($event)"
    ></app-concordance-filter>
  </section>

  <section class="section-container section-numbers">
    @if (numbers.length > 0) {
      @for (num of scope; track num; let i = $index) {
        <button class="number" [id]="num" (click)="fetchNumber(num)" aria-label="Szukaj numeru Stronga">
          <span class="no">({{ num }}) </span><span class="word">{{ numbers[i] }}</span>
        </button>
      }
    }

    @if (numbers.length === 0) {
      <mat-spinner [diameter]="80"></mat-spinner>
    }
  </section>
</article>

<app-loader [isLoading]="isLoading" [transparent]="true"></app-loader>
