<article class="page-wrapper">
  <section class="section-container">
    <app-simple-header [title]="(breakpointsService.observePortrait | async) ? 'UBG (Strong)' : 'UBG w systemie Stronga'" icon="ubg"></app-simple-header>

    <app-strong-filter
      (formChange$)="formChange($event)"
      (verseChange$)="verseChange($event)"
      (compactMode$)="compactMode = $event"
      (fullScreen$)="fullScreen = $event"
      (showGreek$)="showGreek = $event"
      (showStrong$)="showStrong = $event"
      (showTransliteration$)="showTransliteration = $event"
      [form]="form"
      [versesNumber]="book?.text.length"
      [actionsAbsolute]="actionsAbsolute"
      [actionsAbsolutePositionInPx]="actionsAbsolutePositionInPx"
    ></app-strong-filter>

    @if (book?.text.length > 0) {
      <div class="text-container">
        @for (verseItem of book.text; track verseItem.verse; let verse = $index) {
          <div class="chapter">
            <app-strong-verse-number
              [book]="form.get('book').value"
              [chapter]="form.get('chapter').value"
              [verse]="+verseItem.verse"
            ></app-strong-verse-number>
            @for (word of verseItem.text; track i; let first = $first; let i = $index) {
              <button class="word"
                [disabled]="!word.n && !word.tr && !word.na"
                [class.disabled]="!word.n && !word.tr && !word.na"
                [class.nestle-aland]="word.na"
                [class.textus-receptus]="word.tr"
                [class.related]="relatedIndex === i && verse === relatedVerse - 1"
                (mouseenter)="mouseenter(i + word.with, +verseItem.verse)" (mouseleave)="mouseleave()"
                (click)="getNumber(word.n || word.tr || word.na, +verseItem.verse)">
                @if (showStrong) {
                  <div class="word-number">{{ word | strongNumber }}</div>
                }
                @if (showGreek) {
                  <div class="word-transliteration word-root">
                    {{ word | strongRoot : numbersMap }}
                  </div>
                }
                @if (showTransliteration) {
                  <div class="word-transliteration">
                    {{ word | strongTransliteration : numbersMap }}
                  </div>
                }
                <div class="word-translation">{{ word.t || '-' }}</div>
              </button>
            }
            <ng-container *onlyDesktopTablet>
              @if (verseItem.verse === loadingVerseIndex && isDialogLoading) {
                <mat-spinner diameter="50"></mat-spinner>
              }
            </ng-container>
          </div>
        }
      </div>
    }
  </section>
</article>

<app-loader [isLoading]="isLoading"></app-loader>

<app-loader *onlyPortraitLandscape [isLoading]="isDialogLoading"></app-loader>

<app-bible-search-query *onlyDesktop onlyNT="true" class="left" (query$)="bibleSearchQuery($event)"></app-bible-search-query>
