import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../components/snackbar/snackbar-type.enum';
import {
  StrongBottomSheetComponent,
  StrongBottomSheetData,
  StrongBottomSheetResultType
} from '../bottom-sheet/strong-bottom-sheet.component';
import { StrongScrollService } from '../strong-scroll.service';

@Component({
  selector: 'app-strong-verse-number',
  standalone: true,
  templateUrl: './verse-number.component.html',
  styleUrls: ['./verse-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StrongBottomSheetComponent
]
})
export class VerseNumberComponent {
  @Input({ required: true }) protected readonly book: BibliaInfoBook;
  @Input({ required: true }) protected readonly chapter: number;
  @Input({ required: true }) protected readonly verse: number;

  private bottomSheet = inject(MatBottomSheet);
  private snackbarService = inject(SnackbarService);
  private strongScrollService = inject(StrongScrollService);

  protected copy(): void {
    this.snackbarService.open('Skopiowano link', SnackBarType.LINK, 2000);
    this.strongScrollService.scrollToAnchor(this.verse);
  }

  protected openBottomSheet(): void {
    this.bottomSheet.open(StrongBottomSheetComponent, {
      data: ({ book: this.book, chapter: this.chapter, verse: this.verse } as StrongBottomSheetData)
    }).afterDismissed().subscribe((result: StrongBottomSheetResultType) => {
      switch (result) {
        case StrongBottomSheetResultType.COPY_LINK: this.copy(); break;
      }
    });
  }
}
