@if (errorStatus) {
  <div class="error-container">
    <div class="error-status-container">
      @if (errorStatus === DynamicPageError.NOT_FOUND) {
        <mat-icon svgIcon="icons:search_off"></mat-icon>
        <div>{{ notFoundMsg }}</div>
      }
      @if (errorStatus === DynamicPageError.OTHER_ERROR) {
        <mat-icon svgIcon="icons:error"></mat-icon>
        <div>Wystąpił błąd, proszę odświeżyć stronę.</div>
      }
    </div>
  </div>
}
