import { BibliaInfoBook } from '../biblia-info/biblia-info-book';
import { BibliaInfoCode } from '../biblia-info/biblia-info-code';
import { TreeNode } from '../../components/tree/tree.model';

export enum DynamicPageError {
  NOT_FOUND = 404,
  OTHER_ERROR = 500
}

export interface DynamicPageResponse {
  _id: string;
  code: string;
  description: string;
  page: DynamicPage;
}

export interface DynamicPage {
  title: string;
  subtitle?: string;
  pdfUrl?: string;
  epubUrl?: string;
  mobiUrl?: string;
  youtubeUrl?: string;
  author?: string;
  date?: string;
  minutes?: number;
  chips?: string[];
  question?: string;
  book?: DynamicPageBook;
  foreword?: DynamicPageParagraph[];
  navigation?: DynamicPageNavigation[];
  recommended?: [DynamicPageRecommendation, DynamicPageRecommendation];
  otherFromAuthor?: [DynamicPageNavigation, DynamicPageNavigation];
  tableOfContents: string[] | DynamicPageTableOfContents;
  bibliography: DynamicPageBibliographyItem[];
  content: DynamicPageContent[];
  resources?: DynamicPageContent[];
}

export interface DynamicPageNavigation {
  title: string;
  link?: string;
  current?: boolean;
  author?: string;
}

export interface DynamicPageRecommendation extends DynamicPageNavigation {
  author?: string;
}

export interface DynamicPageTableOfContents {
  content: string[] | TreeNode[];
  oneColumn?: boolean;
  asTree?: boolean;
}

export type DynamicPageContent =
  DynamicPageHeader |
  DynamicPageList |
  DynamicPageOrderedList |
  DynamicPageParagraph |
  DynamicPageBible |
  DynamicPageQuotation |
  DynamicPagePoem |
  DynamicPageImage |
  DynamicPageSeparator |
  DynamicPageDialogue |
  DynamicPageTable |
  DynamicPageVideo;

export interface DynamicPageHeader {
  header: string | DynamicPageHeaderContent;
}

export interface DynamicPageHeaderContent {
  text: string;
  separator?: boolean;
  size?: number;
}

export interface DynamicPageList {
  list: DynamicPageListContent[];
}

export interface DynamicPageOrderedList {
  orderedList: DynamicPageListContent[];
}

export type DynamicPageListContent = string | DynamicPageListLink | DynamicPageParagraph;

export interface DynamicPageListLink {
  router: DynamicPageParagraphLinkContent;
}

export interface DynamicPageBook {
  url: {
    frontUrl: string;
    backUrl?: string;
    pdfId: string;
    mobiId: string;
    epubId: string;
    google?: string;
  },
  alt: {
    frontCover: string;
    backCover?: string;
  },
  bookDescription: DynamicPageParagraphContent[][];
  description: DynamicPageParagraphContent[][];
  download: DynamicPageParagraphContent[][];
  header?: string;
}


export interface DynamicPageParagraph {
  paragraph: DynamicPageParagraphContent[];
}

export type DynamicPageParagraphContent =
  (DynamicPageText |
  DynamicPageBold |
  DynamicPageItalic |
  DynamicPageBoldItalic |
  DynamicPageBibliographyNumber |
  DynamicPageParagraphLink |
  DynamicPageParagraphBible |
  DynamicPageStrong) & Styling;

export interface Styling {
  center?: boolean;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}

export type DynamicPageQuotationParagraph =
  DynamicPageText |
  DynamicPageBold |
  DynamicPageItalic |
  DynamicPageBoldItalic |
  DynamicPageBibliographyNumber |
  DynamicPageParagraphBible |
  DynamicPageStrong;

export interface DynamicPageText extends DynamicPageTooltip {
  text: string;
}

export interface DynamicPageBold extends DynamicPageTooltip {
  bold: string;
}

export interface DynamicPageItalic extends DynamicPageTooltip {
  italic: string;
}

export interface DynamicPageBoldItalic extends DynamicPageTooltip {
  boldItalic: string;
}

export interface DynamicPageTooltip {
  tooltip?: string;
}

export interface DynamicPageParagraphBible {
  paragraphBible: DynamicPageParagraphBibleContent;
}

export interface DynamicPageParagraphBibleContent {
  content: DynamicPageBibleContent[];
  brackets?: boolean;
  comma?: boolean;
  dot?: boolean;
}

export interface DynamicPageBibliographyNumber {
  bibliographyNo: number;
}

export interface DynamicPageParagraphLink {
  link: RequireOnlyOne<DynamicPageParagraphLinkContent, 'text' | 'italic'>;
}

export interface DynamicPageParagraphLinkContent {
  text?: string;
  italic?: string;
  textAfter?: string;
  routerLink?: string;
  external?: {
    href: string;
    access?: string;
    notBlank?: boolean;
  }
}

export interface DynamicPageBible {
  bible: DynamicPageBibleContent;
  format?: RequireAtLeastOne<DynamicPageBibleFormat, 'hideFrom' | 'hideIndexes' | 'boldTo' | 'boldIndexes' | 'tooltip' | 'strong'>;
}

export interface DynamicPageBibleContent {
  book: BibliaInfoBook;
  chapter: number;
  verses: string;
  fullPage?: boolean;
  abbreviation?: BibliaInfoCode;
}

export interface DynamicPageBibleFormat {
  hideFrom?: number;
  hideTo?: number;
  hideIndexes?: number[][];
  boldFrom?: number;
  boldTo?: number;
  boldIndexes?: number[][];
  tooltip?: {
    index: number;
    text: string;
  };
  strong?: DynamicPageStrongIndex[];
}

export interface DynamicPageStrongIndex extends DynamicPageTooltip {
  index: number;
  verse: number
  no: number;
}

export interface DynamicPageQuotation {
  quotation: DynamicPageQuotationContent;
}

export interface DynamicPageQuotationContent {
  text?: string;
  paragraph?: DynamicPageQuotationParagraph[];
  author: string;
  authorTooltip?: string;
  bibliographyNo?: number;
  altText?: string;
  altParagraph?: DynamicPageQuotationParagraph[];
  translateMsg?: string;
}

export interface DynamicPagePoem {
  poemes: DynamicPagePoemContent;
}

export type DynamicPagePoemContent = string[][];

export interface DynamicPageImage {
  image: DynamicPageImageContent;
}

export interface DynamicPageImageContent {
  src: string;
  caption: string;
  bibliographyNo?: number;
  alt?: string;
}

export interface DynamicPageSeparator {
  separator: boolean;
}

export interface DynamicPageDialogue {
  dialogue: DynamicPageParagraphContent[];
}

export interface DynamicPageTable {
  table: DynamicPageTableContent;
}

export interface DynamicPageTableContent {
  columns: (string | DynamicPageColumn)[];
  rows: string[][];
  center?: boolean;
}

export interface DynamicPageVideo {
  video: DynamicPageVideoContent;
}

export interface DynamicPageVideoContent {
  id: string;
}

export interface DynamicPageStrong {
  strong: DynamicPageStrongContent;
}

export interface DynamicPageStrongContent extends DynamicPageTooltip {
  text: string;
  no: number;
}

export interface DynamicPageColumn {
  text: string;
  width: number;
}

export interface DynamicPageBibliographyItem {
  content: string | DynamicPageBibliographyContent;
  link?: string;
  access?: string;
}

export interface DynamicPageBibliographyContent {
  part1: string;
  part2: string;
  title: string;
}
