import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Location, ViewportScroller } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ConcordanceDialogComponent } from './dialog/concordance-dialog.component';
import { ConcordanceFilterComponent } from './filter/concordance-filter.component';
import { ConcordanceNumbers } from './concordance.model';
import { LoaderComponent } from '../../../../components/loader/loader.component';
import { SimpleHeaderComponent } from '../../../../components/simple-header/simple-header.component';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../components/snackbar/snackbar-type.enum';
import { Strong } from '../../../../services/strong/strong.model';
import { StrongService } from '../../../../services/strong/strong.service';

@Component({
  selector: 'app-concordance',
  templateUrl: './concordance.component.html',
  styleUrls: ['./concordance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ConcordanceDialogComponent,
    ConcordanceFilterComponent,
    LoaderComponent,
    MatDialogModule,
    MatProgressSpinnerModule,
    SimpleHeaderComponent
]
})
export class ConcordanceComponent implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly dialog = inject(MatDialog);
  private readonly location = inject(Location);
  private readonly route = inject(ActivatedRoute);
  private readonly snackbarService = inject(SnackbarService);
  private readonly strongService = inject(StrongService);
  private readonly viewportScroller = inject(ViewportScroller);

  protected scope = Array.from({ length: 500 },(v,k)=> k + 1);
  protected numbers: string[] = [];
  protected disableChipIndex = 0;
  protected selectedChipIndex = 0;
  protected isLoading = false;

  ngOnInit(): void {
    this.updateScopeBasedOnAnchorFragment();
    this.fetchNumbersFromScope();
  }

  protected fetchNumber(number: number): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.strongService.getNumber(number).pipe(
      catchError((errorRes: HttpErrorResponse) => {
        this.snackbarService.open(errorRes.error.message, SnackBarType.ERROR, 3000);
        return of(null);
      })
    ).subscribe(strong => {
      this.openConcordanceDialog(strong);
    });
  }

  protected fetchWord(word: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.strongService.getWord(word).pipe(
      catchError((errorRes: HttpErrorResponse) => {
        this.snackbarService.open(errorRes.error.message, SnackBarType.ERROR, 3000);
        return of(null);
      })
    ).subscribe(strong => {
      this.openConcordanceDialog(strong);
    });
  }

  protected renderStrongWord(value: string): void {
    if (Number.isInteger(+value)) {
      this.fetchNumber(+value);
    } else {
      this.fetchWord(value);
    }
  }

  protected filterNumbers(scope: string): void {
    const [from, to] = [...scope.split(' ').join('').split('-')];

    if (Number.parseInt(from) !== this.scope[0]) {
      this.scope = this.getScopeFromTo(+from, +to);
      this.fetchNumbersFromScope();
    }
  }

  private getScopeFromTo(from: number, to: number): number[] {
    const scope = Array.from({length: (+to) - (+from) + 1}, (v, k) => k + (+from));
    this.disableChipIndex = (scope[0] - 1) / 500;
    return scope;
  }

  private openConcordanceDialog(strong: Strong): void {
    if (!strong) {
      this.isLoading = false;
      this.cdr.markForCheck();
      return;
    }
      this.dialog.open<ConcordanceDialogComponent, Strong>(ConcordanceDialogComponent, {
        data: strong, panelClass: ['reading', 'full', 'concordance']
      }).afterOpened().subscribe(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  private fetchNumbersFromScope(): void {
    this.numbers = [];
    this.getNumbersObservable().pipe(
      catchError((errorRes: HttpErrorResponse) => {
        this.snackbarService.open(errorRes.error.message, SnackBarType.ERROR, 3000);
        return of([]);
      })
    ).subscribe(numbers => {
      this.numbers = numbers;
      this.isLoading = false;
      this.cdr.markForCheck();
      this.saveNumbersInLocalStorage(this.numbers,  this.scope[0]);
      this.scrollToNumber();
    });
  }

  private getNumbersObservable(): Observable<string[]> {
    const numbers = this.getNumbersFromLocalStorage()[this.scope[0]];
    return numbers?.length > 0 ? of(numbers) : this.strongService.getNumbersFromTo(this.scope[0], this.scope[this.scope.length - 1]);
  }

  private scrollToNumber(): void {
    setTimeout(() => {
      const number = +this.route.snapshot.fragment;
      const isNumber = Number.isInteger(number);
      if (isNumber && number > 0) {
        this.viewportScroller.scrollToAnchor(number + '');
      }
      this.clearAnchorFromUrl();
    }, 0);
  }

  private clearAnchorFromUrl(): void {
    const pathWithoutHash = this.location.path(false);
    this.location.replaceState(pathWithoutHash);
  }

  private updateScopeBasedOnAnchorFragment(): void {
    const number = +this.route.snapshot.fragment;
    const isNumber = Number.isInteger(number);

    if (isNumber && number > 0) {
      for (let i = 0; i < 5624; i = i + 500) {
        if (number <= i) {
          this.scope = Array.from({ length: 500 },(v,k)=> i + k - 499);
          const index = i / 500 - 1;
          this.selectedChipIndex = this.disableChipIndex = index;
          this.cdr.markForCheck();
          break;
        }
      }
    }
  }

  private getNumbersFromLocalStorage(): ConcordanceNumbers {
    return JSON.parse(localStorage.getItem('concordance-words')) || {};
  }

  private saveNumbersInLocalStorage(numbers: string[], from: number) {
    const numbersFromLocalStorage = this.getNumbersFromLocalStorage() || {};
    numbersFromLocalStorage[from + ''] = numbers;
    localStorage.setItem('concordance-words', JSON.stringify(numbersFromLocalStorage));
  }
}
