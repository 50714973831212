import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';

import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { BreakpointsService } from '../../../../services/breakpoints.service';
import { environment } from '../../../../../environments/environment';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

export enum StrongBottomSheetResultType {
  COPY_LINK,
  NAVIGATE_TO_BIBLE,
}

export interface StrongBottomSheetData {
  book: BibliaInfoBook,
  chapter: number,
  verse: number,
}

@Component({
  selector: 'app-strong-bottom-sheet',
  templateUrl: './strong-bottom-sheet.component.html',
  styleUrls: ['./strong-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClipboardModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    SharedPipesModule,
  ]
})
export class StrongBottomSheetComponent {
    protected readonly data: StrongBottomSheetData = inject(MAT_BOTTOM_SHEET_DATA);
    protected readonly bottomSheetRef: MatBottomSheetRef<StrongBottomSheetComponent, StrongBottomSheetResultType> = inject(MatBottomSheetRef);
    protected readonly breakpointsService = inject(BreakpointsService);
    private readonly router = inject(Router);

  get url(): string {
    return `${environment.domain}${this.router.routerState.snapshot.url}#${this.data.verse}`;
  }

  get queryParams(): {} {
    return { przeklad: 'ug', ksiega: this.data.book, rozdzial: this.data.chapter, werset: this.data.verse };
  }

  copy(): void {
    this.bottomSheetRef.dismiss(StrongBottomSheetResultType.COPY_LINK);
  }

  openBible($event: MouseEvent): void {
    if ($event.button === 0) {
      this.bottomSheetRef.afterDismissed().subscribe(() => this.navigateToBible())
    } else if ($event.button === 1) {
      this.navigateToBibleInBlankPage();
    }
    this.bottomSheetRef.dismiss();
  }

  private navigateToBible(): void {
    this.bottomSheetRef.dismiss();
    this.router.navigate(["../../biblia/przeklady"],{
      queryParams: this.queryParams,
    });
  }

  private navigateToBibleInBlankPage(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['biblia/przeklady'], {
        queryParams: this.queryParams,
      }));
    window.open(url, '_blank');
  }
}
