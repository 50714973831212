import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { DynamicPageError } from '../../services/dynamic-page/dynamic-page.model';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule
]
})
export class ErrorPageComponent {
  @Input({ required: true }) readonly errorStatus: DynamicPageError;
  @Input({ required: true }) readonly notFoundMsg: string;

  protected readonly DynamicPageError = DynamicPageError;
}
